import React, { useContext } from "react";
import Layout from "Layout";
import Pages from "Pages";
import PagesAuth from "PagesAuth/index";
import { consumer } from "State";
import { ToastContainer } from 'react-toastify';


const App = () => {
  const { state } = useContext(consumer);
  return (
    <>
      {state.shop ? (
        <Layout>
          <Pages />
        </Layout>
      ) : (
          <PagesAuth />
        )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
        rtl={false}
        draggable={false}
        pauseOnHover={false}
      />
    </>
  )
}

export default App;
