import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import { configDevelopment, configProduction } from "config";
const config = window.location.hostname.includes("store.stovecook") ? configProduction : configDevelopment;
firebase.initializeApp(config);
export const auth = firebase.auth();
export const db = firebase.firestore();


export const createUser = async (email, password) => {
    const UserCredential = await auth.createUserWithEmailAndPassword(email, password);
    return UserCredential
}

export const signIn = async (email, password) => {
    const UserCredential = await auth.signInWithEmailAndPassword(email, password);
    return UserCredential
}

export const authStateChanged = async () => {
    let user = await new Promise((resolve, reject) => {
        auth.onAuthStateChanged((user) => resolve(user), error => reject(error));
    })
    return user;
}

export const getCurrentUser = async () => {
    let user = auth.currentUser;
    return user;
}

export const getShopByEmail = async (email) => {
    let shopDocs = []
    let shopquery = db.collection("shops").where("email", "==", email.trim().toLowerCase());
    let shopSnapshot = await shopquery.get();
    shopSnapshot.forEach(documentSnapshot => shopDocs.push(documentSnapshot.data()));
    return shopDocs[0];
}

export const getCredential = async (email, password) => {
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);
    return credential
}

export const signOut = async () => {
    await auth.signOut();
    return;
}


export const onIngredientListChange = (setIngredientList) => {
    const ingredientListRef = db.collection("ingredientList")
    const ununsubscribe = ingredientListRef.onSnapshot(async (querySnapshot) => {
        let ingredientList = []
        querySnapshot.forEach(queryDocumentSnapshot => ingredientList.push(queryDocumentSnapshot.data()))
        setIngredientList(ingredientList);
    });
    return ununsubscribe
}


export const onInventoryListChange = async (id, setInventry) => {
    const inventoryDocRef = db.collection("inventories").doc(id);
    const ununsubscribe = inventoryDocRef.onSnapshot((inventoryDoc) => {
        setInventry(inventoryDoc.get("inventory") || [])
    });
    return ununsubscribe
}

export const updateInventory = async (id, inventoryList) => {
    const inventoryDocRef = db.collection("inventories").doc(id);
    await inventoryDocRef.set({
        inventory: inventoryList
    })
}

export const getShopOrder = async (shopId, handleOrderList) => {
    const ordersListRef = await db.collection("transactions").where("type", "==", "shop").where("shopId", "==", shopId);
    const ununsubscribe = ordersListRef.onSnapshot(async (querySnapshot) => {
        const orders = [];
        querySnapshot.forEach((doc) => {
            orders.push(doc.data());
        });
        handleOrderList(orders);
    });
    return ununsubscribe
}

export const updateShopOrder = async (transactionId, value) => {
    const transactionRef = db.collection("transactions").doc(transactionId);
    await transactionRef.update({
        status: value
    })
}

