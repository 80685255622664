import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import Inventory from "Pages/Inventory";
import ShopDetails from "Pages/ShopDetails";
import Orders from "Pages/Orders";

const App = () => {
    return (
        <Switch>
            <Route path="/orders" exact={true} >
                <Orders />
            </Route>
            <Route path="/inventory" exact={true}>
                <Inventory />
            </Route>
            <Route path="/store" exact={true} >
                <ShopDetails />
            </Route>
            <Redirect to="/orders" />
        </Switch>
    );
}

export default App;