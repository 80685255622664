import React, { useContext, useState } from 'react';
import { signOut } from "Services/firebase";
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { consumer } from "State";


const useStyles = makeStyles({
    marginRight: {
        display: "block",
        marginRight: "5px"
    },
    container: {
        width: "80vw",
        height: "100vh",
        maxWidth: "300px",
        padding: "40px 16px 0 16px",
        backgroundColor: "#FF3539",
    },
    divider: {
        backgroundColor: "#fff",
        marginBottom: 24,
        opacity: 0.5
    },
    feedback: {
        display: "flex",
        alignItems: "center",
        color: "#fff",
        fontWeight: 700,
        fontSize: "1.2em",
        marginBottom: 24
    },
    logout: {
        color: "#fff",
        fontWeight: "normal",
        fontSize: "1.2em",
        marginBottom: 24
    },
    confirmLogoutContain: {
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#898989",
        zIndex: 99999
    },
    confirmLogoutText: {
        color: "#fff",
        width: "80%",
        textAlign: "center",
        marginBottom: "40px",
        fontSize: "1.3em"
    },
    confirmLogoutButtonYes: {
        width: "100%",
        margin: 0,
        height: "3.5em",
        textTransform: "capitalize",
        fontWeight: "bolder",
        fontSize: "0.9em",
        color: "black",
        backgroundColor: "#fff"
    },
    confirmLogoutButtonNo: {
        width: "100%",
        margin: 0,
        height: "3.5em",
        textTransform: "capitalize",
        fontWeight: "bolder",
        fontSize: "0.9em",
        color: "#fff",
        backgroundColor: "black"
    }
});

export default function SideBar() {
    const classes = useStyles();
    const { state, action } = useContext(consumer);
    const [confirmLogout, setConfirmLogout] = useState(false);

    const feedbackHandler = () => {
        action.setSideBarIsOpen(false);
        window.open("https://docs.google.com/forms/d/e/1FAIpQLSeqpaUhe1tIkKRbucnjTovV6zy2lL6GNrXprY4_R5S2wYkI-Q/viewform?usp=sf_link", "_blank")
    }

    const handlersignOut = async () => {
        await signOut();
        window.location.reload();
    }


    return (
        <>
            <Drawer anchor="left" open={state.sideBarIsOpen} onClose={() => action.setSideBarIsOpen(false)}>
                <div className={classes.container}>
                    <Typography style={{ color: "white", cursor: "pointer" }} variant="h3" gutterBottom={true}>{state.shop && state.shop.name}</Typography>
                    <Divider className={classes.divider} />
                    <div style={{ color: "white", cursor: "pointer" }} className={classes.feedback} onClick={feedbackHandler}>
                        <span className={classes.marginRight}>Give feedback</span> <OpenInNewIcon fontSize="inherit" />
                    </div>
                    <div style={{ color: "white", cursor: "pointer" }} onClick={() => setConfirmLogout(true)} className={classes.logout}>
                        Logout
                    </div>
                </div>
            </Drawer>
            {confirmLogout && (
                <div className={classes.confirmLogoutContain}>
                    <h2 className={classes.confirmLogoutText}>Are you sure you want to log out?</h2>
                    <ButtonBase onClick={handlersignOut} className={classes.confirmLogoutButtonYes}> Yes </ButtonBase>
                    <ButtonBase onClick={() => setConfirmLogout(false)} className={classes.confirmLogoutButtonNo}>No</ButtonBase>
                </div>
            )}
        </>
    );
}