import React from 'react';
import { useHistory, useLocation } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';


import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import LocalMallIcon from '@material-ui/icons/LocalMall';


import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import StoreIcon from '@material-ui/icons/Store';

import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'


const useStyles = makeStyles({
    root: {
        width: "100%",
        backgroundColor: "#E5E5E5",
        minHeight: "56px",
        maxWidth: "576px",
        margin: "auto",
    },
    icon: {
        color: "#00000099",
        "&$selected": {
            color: "black",
        }
    },
    selected: {
        color: "black",
    }
});

const Footer = () => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();

    return (
        <BottomNavigation
            showLabels
            value={location.pathname}
            onChange={(event, newValue) => {
                history.push(newValue);
            }}
            className={classes.root}
        >
            <BottomNavigationAction
                label="Orders"
                classes={{ root: classes.icon, selected: classes.selected }}
                value="/orders"
                icon={location.pathname === "/orders" ? <ShoppingCartIcon /> : <ShoppingCartOutlinedIcon />}
            />
            <BottomNavigationAction
                label="Inventory"
                classes={{ root: classes.icon, selected: classes.selected }}
                value="/inventory"
                icon={location.pathname === "/inventory" ? <LocalMallIcon /> : <LocalMallOutlinedIcon />}
            />
            <BottomNavigationAction
                label="Store"
                classes={{ root: classes.icon, selected: classes.selected }}
                value="/store"
                icon={location.pathname === "/store" ? <StoreIcon /> : <StoreOutlinedIcon />}
            />
        </BottomNavigation>
    );
}

export default Footer;