import React, { useContext, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import Button from "@material-ui/core/Button";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from "yup";
import { updateInventory } from "Services/firebase";
import { consumer } from "State";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingTop: "64px",
        overFlow: 'auto',
    },
    textContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end"
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    }
}));


const validationSchema = Yup.object().shape({
    id: Yup.string().required('Ingredient name is required'),
    unit: Yup.number().required("Unit is required"),
    unitPrice: Yup.number().min(0.4, "Minimum unit price is 0.4").required("Unit is required"),
});



const Inventory = () => {
    const classes = useStyles();
    const [searchText, setSearchText] = useState("");
    const { state } = useContext(consumer);
    const [error, setError] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [showInventoryDialog, setShowInventoryDialog] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState({
        id: "",
        unit: "",
        unitPrice: ""
    });


    const openInventoryDialog = (isEditing, initialValues) => {
        setShowInventoryDialog(true);
        setIsEditing(isEditing);
        setInitialValues(initialValues);
    };

    const closeInventoryDialog = () => {
        setShowInventoryDialog(false);
    };

    const confirmDelete = async (name, id) => {
        const result = window.confirm(`${name} will be deleted from inventory list`);
        if (result) {
            const inventoryList = [...state.inventoryList]
            const foundIndex = inventoryList.findIndex(inventory => inventory.id === id);
            inventoryList.splice(foundIndex, 1);
            await updateInventory(state.shop.id, inventoryList);
        }
    }

    const addName = (inventoryList, ingredientList) => {
        const inventoryListAndName = []
        inventoryList.forEach((inventory) => {
            const foundIngredient = ingredientList.find(ingredient => ingredient.id === inventory.id);
            if (foundIngredient) {
                inventoryListAndName.push({
                    name: foundIngredient.name,
                    ...inventory
                })
            }
        })
        return inventoryListAndName
    }

    return (
        <>
            <div className={classes.root}>
                <Typography variant="h3" gutterBottom={true}>Inventory List</Typography>
                <TextField
                    color="secondary"
                    label="Search Inventories"
                    placeholder="Search Inventories"
                    variant="filled"
                    margin="dense"
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                    fullWidth
                />
                <TableContainer>
                    <Table size="small" style={{ width: "auto", tableLayout: "auto", minWidth: "100%" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Inventory</TableCell>
                                <TableCell>Unit</TableCell>
                                <TableCell>Unit price</TableCell>
                                <TableCell style={{ width: "60px" }}>Edit</TableCell>
                                <TableCell style={{ width: "80px" }}>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!addName(state.inventoryList, state.ingredientList).filter(inventory => inventory.name.trim().toLocaleLowerCase().includes(searchText.trim().toLocaleLowerCase())).length ? (
                                addName(state.inventoryList, state.ingredientList).filter(inventory => inventory.name.trim().toLocaleLowerCase().includes(searchText.trim().toLocaleLowerCase())).map((inventory, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ textTransform: "capitalize" }}>{inventory.name}</TableCell>
                                        <TableCell style={{ textTransform: "capitalize" }}>{inventory.unit}</TableCell>
                                        <TableCell style={{ textTransform: "capitalize" }}> £{inventory.unitPrice.toFixed(2)}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                size="small"
                                                onClick={() => openInventoryDialog(true, {
                                                    id: inventory.id,
                                                    unit: inventory.unit,
                                                    unitPrice: inventory.unitPrice
                                                })}
                                                variant="contained"
                                                color="primary"
                                            >   <EditIcon /></IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                size="small"
                                                onClick={async () => {
                                                    try {
                                                        await confirmDelete(inventory.name, inventory.id);
                                                    } catch (error) {
                                                        toast.error("Failed to delete");
                                                    }
                                                }}
                                                variant="contained"
                                                color="secondary"
                                            >   <DeleteIcon /></IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={4}
                                            style={{
                                                border: "none",
                                                textAlign: "center",
                                                paddingTop: 30,
                                            }}
                                        >
                                            {!!searchText.trim() ? (
                                                <Typography>No Match Found!!!</Typography>
                                            ) : (
                                                    <Typography>No Inventory Added!!!</Typography>
                                                )}
                                        </TableCell>
                                    </TableRow>
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={Boolean(error)}
                autoHideDuration={3000}
                onClose={() => setError("")}
                message={error}

            />
            <Fab onClick={() => openInventoryDialog(false, {
                id: "",
                unit: "",
                unitPrice: ""
            })} className={classes.fab} color="secondary" aria-label="add">
                <AddIcon />
            </Fab>
            <Dialog
                fullWidth={true}
                open={showInventoryDialog}
                onClose={closeInventoryDialog}
                aria-labelledby="Add and update inventory"
                aria-describedby="Add and update inventory"
            >
                <Formik
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={async (values, actions) => {
                        if (isEditing) {
                            try {
                                const inventoryList = [...state.inventoryList]
                                const foundIndex = inventoryList.findIndex(inventory => inventory.id === values.id);
                                if (foundIndex > -1) {
                                    inventoryList[foundIndex].unit = values.unit;
                                    inventoryList[foundIndex].unitPrice = values.unitPrice;
                                } else {
                                    inventoryList.push(values);
                                }
                                await updateInventory(state.shop.id, inventoryList);
                                closeInventoryDialog();
                                actions.setSubmitting(false);

                            } catch (error) {
                                toast.error("Failed to update");
                            }
                        } else {
                            try {
                                const inventoryList = [...state.inventoryList]
                                const foundIndex = inventoryList.findIndex(inventory => inventory.id === values.id);
                                if (foundIndex > -1) {
                                    inventoryList[foundIndex].unit = inventoryList[foundIndex].unit + values.unit;
                                    inventoryList[foundIndex].unitPrice = values.unitPrice;
                                } else {
                                    inventoryList.push(values);
                                }
                                await updateInventory(state.shop.id, inventoryList);
                                closeInventoryDialog();
                                actions.setSubmitting(false);
                            } catch (error) {
                                toast.error("Failed to add");
                            }
                        }
                    }}
                >
                    {formik => (
                        <>
                            <DialogTitle color="secondary">{isEditing ? "Update Inventory" : "Add Inventory"}</DialogTitle>
                            <DialogContent>
                                <TextField
                                    label="Ingredient name"
                                    name="id"
                                    type="text"
                                    select
                                    disabled={isEditing}
                                    placeholder="Ingredient name"
                                    {...formik.getFieldProps("id")}
                                    margin="dense"
                                    helperText={formik.touched.id && formik.errors.id}
                                    error={formik.touched.id && formik.errors.id ? true : false}
                                    fullWidth
                                >
                                    {state.ingredientList.map((ingredient) => <MenuItem value={ingredient.id}>{ingredient.name}</MenuItem>)}
                                </TextField>
                                <TextField
                                    label="Unit"
                                    name="unit"
                                    type="number"
                                    placeholder="Unit"
                                    {...formik.getFieldProps("unit")}
                                    margin="dense"
                                    helperText={formik.touched.unit && formik.errors.unit}
                                    error={formik.touched.unit && formik.errors.unit ? true : false}
                                    fullWidth
                                />
                                <TextField
                                    label="Unit Price"
                                    name="unitPrice"
                                    type="number"
                                    placeholder="Unit Price"
                                    {...formik.getFieldProps("unitPrice")}
                                    margin="dense"
                                    helperText={formik.touched.unitPrice && formik.errors.unitPrice}
                                    error={formik.touched.unitPrice && formik.errors.unitPrice ? true : false}
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button size="small" variant="contained" color="secondary" onClick={() => {
                                    closeInventoryDialog();
                                }}>Cancel</Button>
                                <Button size="small" variant="contained" color="secondary" onClick={async () => formik.submitForm()}>
                                    {isEditing ? "Update" : "Add"}
                                </Button>
                            </DialogActions>

                        </>
                    )}
                </Formik>
            </Dialog>
        </>
    );


}

export default Inventory;


