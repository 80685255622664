import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { toast } from 'react-toastify';
import { getShopOrder, updateShopOrder } from "Services/firebase";
import { consumer } from "State";
import { format } from 'date-fns';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingTop: "64px",
        overFlow: 'auto',
    },
    itemContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textTransform: "capitalize",
        marginRight: "8px"
    },
    itemLeftText: {
        marginRight: "8px"
    }
}));



const Orders = () => {
    const classes = useStyles();
    const { state } = useContext(consumer);
    const [activeTab, setActiveTab] = useState("incomplete");
    const [ordersCompleted, setOrdersCompleted] = useState([]);
    const [ordersInCompleted, setOrdersInCompleted] = useState([]);

    const updateOrder = async (transitionId, value, message) => {
        await updateShopOrder(transitionId, value);
        toast(message);
    }

    useEffect(() => {
        getShopOrder(state.shop.id, arrangeOrder);
    }, [state.shop.id])

    const arrangeOrder = async (orders) => {
        try {
            const ordersInCompleted = [];
            const ordersCompleted = [];
            orders.forEach(order => {
                if (order.status === "incomplete") {
                    ordersInCompleted.push(order);
                } else if (order.status === "complete") {
                    ordersCompleted.push(order);
                }
            });
            setOrdersInCompleted(ordersInCompleted);
            setOrdersCompleted(ordersCompleted);
        } catch (error) {
            toast("Unable to load your orders");
        }
    }







    return (
        <>
            <div className={classes.root}>
                <Typography variant="h3" gutterBottom={true}>Orders</Typography>
                {/* <AppBar position="static"> */}
                <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value)} aria-label="Orders to show" centered={true}>
                    <Tab value="incomplete" label="In progress" />
                    <Tab value="complete" label="Complete" />
                </Tabs>
                {/* </AppBar> */}
                {activeTab === "incomplete" && (
                    ordersInCompleted.length !== 0 ? (
                        <List>
                            {ordersInCompleted.map((order, index) => {
                                return (
                                    <ListItem key={index} role={undefined} disableGutters dense divider >
                                        <ListItemText
                                            primary={
                                                <div className={classes.itemContainer}>
                                                    <Typography
                                                        className={classes.itemLeftText}
                                                        component="span"
                                                        variant="body2"
                                                        color="textPrimary"
                                                    >Date:</Typography>
                                                    <span>
                                                        {format(new Date(order.timestamp), 'ccc do MMM yyyy h:mm a')}
                                                    </span>
                                                </div>
                                            }
                                            secondary={
                                                <>
                                                    {order.items.map((item, idx) => (
                                                        <div key={idx} className={classes.itemContainer}>
                                                            <Typography
                                                                className={classes.itemLeftText}
                                                                component="span"
                                                                variant="body2"
                                                                color="textPrimary"
                                                            >{item.name}</Typography>
                                                            <span>£{item.price.toFixed(2)}</span>
                                                        </div>
                                                    ))}
                                                    <div className={classes.itemContainer}>
                                                        <Typography
                                                            className={classes.itemLeftText}
                                                            component="span"
                                                            variant="body2"
                                                            color="textPrimary"
                                                        >Total Price:</Typography>
                                                        <span>£{order.amount.toFixed(2)}</span>
                                                    </div>
                                                </>
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" onClick={() => updateOrder(order.id, "complete", "Order has been successfully marked as completed")}>
                                                <CheckCircleOutlineIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                        </List>
                    ) : (
                            <Typography
                                align="center"
                                variant="body2"
                                color="textPrimary"
                            >
                                No order is currently requested
                            </Typography>
                        )
                )}
                {activeTab === "complete" && (
                    ordersCompleted.length !== 0 ? (
                        <List>
                            {ordersCompleted.map((order, index) => {
                                return (
                                    <ListItem key={index} role={undefined} disableGutters dense divider >
                                        <ListItemText
                                            primary={
                                                <div className={classes.itemContainer}>
                                                    <Typography
                                                        className={classes.itemLeftText}
                                                        component="span"
                                                        variant="body2"
                                                        color="textPrimary"
                                                    >
                                                        Date:                                               </Typography>
                                                    <span>
                                                        {format(new Date(order.timestamp), 'ccc do MMM yyyy h:mm a')}
                                                    </span>
                                                </div>
                                            }
                                            secondary={
                                                <>
                                                    {order.items.map((item, idx) => (
                                                        <div key={idx} className={classes.itemContainer}>
                                                            <Typography
                                                                className={classes.itemLeftText}
                                                                component="span"
                                                                variant="body2"
                                                                color="textPrimary"
                                                            >{item.name}</Typography>
                                                            <span>£{item.price.toFixed(2)}</span>
                                                        </div>
                                                    ))}
                                                    <div className={classes.itemContainer}>
                                                        <Typography
                                                            className={classes.itemLeftText}
                                                            component="span"
                                                            variant="body2"
                                                            color="textPrimary"
                                                        >Total Price:</Typography>
                                                        <span>£{order.amount.toFixed(2)}</span>
                                                    </div>
                                                </>
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" onClick={() => updateOrder(order.id, "incomplete", "Order has been successfully moved to in progress")}>
                                                <CheckCircleIcon color="secondary" />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                        </List>
                    ) : (
                            <Typography
                                align="center"
                                variant="body2"
                                color="textPrimary"
                            >
                                No order completed yet
                            </Typography>
                        )
                )}
            </div>
        </>
    );


}

export default Orders;