import React, { useContext, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { authStateChanged, getShopByEmail } from "Services/firebase";
import { consumer } from "State";
import Login from "PagesAuth/Login";
import SignUp from "PagesAuth/SignUp";

const useStyles = makeStyles(() => ({
    backgroundImage: {
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    video: {
        objectFit: "cover",
        width: "100vw",
        height: "100vh",
        position: "fixed"
    }

}));

const PagesAuth = () => {
    const classes = useStyles();
    const { action } = useContext(consumer);
    useEffect(() => {
        (async () => {
            try {
                const user = await authStateChanged();
                if (user) {
                    const shop = await getShopByEmail(user.email);
                    if (shop) {
                        action.setShop(shop);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [])

    return (
        <>
            <div className={classes.backgroundImage}>
                <video className={classes.video} playsInline autoPlay loop muted>
                    <source src="https://firebasestorage.googleapis.com/v0/b/stove-e851c.appspot.com/o/login-bg.mp4?alt=media&token=37b165fd-83f2-49ca-b779-5e1225755702" type="video/mp4" />
                </video>
            </div>
            <Switch>
                <Route path="/login" exact={true}>
                    <Login />
                </Route>
                <Route path="/signup" exact={true} >
                    <SignUp />
                </Route>
                <Redirect to="/login" />
            </Switch>
        </>
    );
}

export default PagesAuth;