import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { onIngredientListChange, onInventoryListChange } from "Services/firebase";
const state = React.createContext();



const Provider = (props) => {
    const { children } = props;
    const [shop, setShop] = useState(null);
    const [ingredientList, setIngredientList] = useState([]);
    const [inventoryList, setInventoryList] = useState([]);
    const [sideBarIsOpen, setSideBarIsOpen] = useState(false);

    useEffect(() => {
        if (shop && shop.id) {
            (() => {
                try {
                    onIngredientListChange(setIngredientList);
                    onInventoryListChange(shop.id, setInventoryList);
                } catch (error) {
                    toast.error("Failed load ingredient list");
                }
            })();
        }
    }, [shop])

    return (
        <state.Provider
            value={{
                state: {
                    shop,
                    sideBarIsOpen,
                    ingredientList,
                    inventoryList
                },
                action: {
                    setShop: (shop) => setShop(shop),
                    setSideBarIsOpen: (bool) => setSideBarIsOpen(bool)
                }
            }}
        >
            {children}
        </state.Provider>
    );

}


export default Provider;
export { state as consumer }