import React, { useContext, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { getCurrentUser, getCredential } from "Services/firebase";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import { consumer } from "State";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "64px",
        width: "100%"
    },
    textContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start"
    },
    marginRight: {
        marginRight: theme.spacing(1)
    },
    textAlign: {
        textAlign: "right"
    },
    button: {
        marginTop: theme.spacing(2),
        color: "#fff",
        backgroundColor: "#FF3539",
        "&:hover": {
            color: "#fff",
            backgroundColor: "#FF3539",
        },
        "&:focused": {
            color: "#fff",
            backgroundColor: "#FF3539",
        },
        "&:active": {
            color: "#fff",
            backgroundColor: "#FF3539",
        }
    },
    textField: {
        color: "black !important",
        backgroundColor: "#fff !important",
        "&::placeholder": {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: "#BDBDBD !important"
        },
        "&:-ms-input-placeholder": {
            /* Internet Explorer 10-11 */
            color: "#BDBDBD"
        },
        "&::-ms-input-placeholder": {
            /* Microsoft Edge */
            color: "#BDBDBD"
        }
    },
    formHelperText: {
        textAlign: "center"
    },
    updateButton: {
        color: "#fff",
        minWidth: "72px",
        backgroundColor: "#FF3539",
        "&:hover": {
            color: "#fff",
            backgroundColor: "#FF3539",
        },
        "&:focused": {
            color: "#fff",
            backgroundColor: "#FF3539",
        },
        "&:active": {
            color: "#fff",
            backgroundColor: "#FF3539",
        }
    },
}));



const ShopDetails = () => {
    const classes = useStyles();
    const { state } = useContext(consumer);

    const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
    const [values, setValues] = useState({
        oldPassword: '',
        newPassword: '',
        reEnterNewPassword: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const [message, setMessage] = useState("");

    const handleClickOpen = () => {
        setOpenPasswordDialog(true);
    };

    const handleClose = () => {
        setOpenPasswordDialog(false);
        setValues({
            oldPassword: '',
            newPassword: '',
            reEnterNewPassword: ''
        });
        setError("");
    };

    const handleChange = (prop) => (event) => {
        if (error) setError("");
        setValues({ ...values, [prop]: event.target.value });
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if (!values.oldPassword || !values.newPassword || !values.reEnterNewPassword) {
                setError("All field are required");
                return;
            }
            if (values.newPassword !== values.reEnterNewPassword) {
                setError("New password did not match");
                return;
            }

            if (error) setError("");
            setIsLoading(true);

            let user = await getCurrentUser();
            try {
                let credential = await getCredential(user.email, values.oldPassword);
                await user.reauthenticateWithCredential(credential);
            } catch {
                setError("Incorrect current password");
                setIsLoading(false);
                return;
            }
            await user.updatePassword(values.newPassword);
            setMessage("Password updated successfully");
            setIsLoading(false);
            setOpenPasswordDialog(false);
        } catch (error) {
            setError(error.message);
            setIsLoading(false);
        }
    };


    return (
        <>
            <div className={classes.root}>
                {state.shop && (
                    <>
                        <Typography variant="h3" gutterBottom={true}>{state.shop.name}</Typography>
                        <p className={classes.textContainer}>
                            <span className="textDark">Account Holder:</span>
                            <span className="textLight capitalize">{state.shop.accountHolder}</span>
                        </p>
                        <p className={classes.textContainer}>
                            <span className="textDark">Email:</span>
                            <span className="textLight capitalize"> {state.shop.email}</span>
                        </p>
                        <p className={classes.textContainer}>
                            <span className="textDark">Phone:</span>
                            <span className="textLight">{state.shop.phone}</span>
                        </p>
                        <p className={classes.textContainer}>
                            <span className="textDark">Borough:</span>
                            <span className="textLight"> {state.shop.borough}</span>
                        </p>
                        <p className={classes.textContainer}>
                            <span className="textDark">Postal Code:</span>
                            <span className="textLight">{state.shop.postalCode}</span>
                        </p>
                        <p className={classes.textContainer}>
                            <span className="textDark">Opening Time:</span>
                            <span className="textLight">{format(new Date(state.shop.openingTime), 'h:mm a')}</span>
                        </p>
                        <p className={classes.textContainer}>
                            <span className="textDark">Closing Time:</span>
                            <span className="textLight">{format(new Date(state.shop.closingTime), 'h:mm a')}</span>
                        </p>
                        <p className={classes.textContainer}>
                            <span className="textDark">Address:</span>
                            <span className="textLight">{state.shop.address}</span>
                        </p>
                    </>
                )}
            </div>

            <Button onClick={handleClickOpen} disabled={isLoading} fullWidth={true} className={classes.button} variant="contained">Change Password</Button>


            <Dialog open={openPasswordDialog}>
                <DialogTitle>Account</DialogTitle>
                <DialogContent>
                    <DialogContentText>Edit account settings and change your password here.</DialogContentText>

                    <FormControl size="small" fullWidth={true} variant="outlined" margin="normal">
                        <OutlinedInput
                            id="oldPassword"
                            autoComplete="off"
                            className={classes.textField}
                            placeholder="Enter Current password"
                            type='text'
                            value={values.oldPassword}
                            onChange={handleChange("oldPassword")}
                        />
                    </FormControl>

                    <FormControl size="small" fullWidth={true} variant="outlined" margin="normal">
                        <OutlinedInput
                            id="newPassword"
                            autoComplete="off"
                            className={classes.textField}
                            placeholder="Enter New Password"
                            type='text'
                            value={values.newPassword}
                            onChange={handleChange('newPassword')}
                        />
                    </FormControl>

                    <FormControl size="small" fullWidth={true} variant="outlined" margin="normal">
                        <OutlinedInput
                            id="reEnterNewPassword"
                            autoComplete="off"
                            className={classes.textField}
                            placeholder="Re-enter New Password"
                            type='text'
                            value={values.reEnterNewPassword}
                            onChange={handleChange('reEnterNewPassword')}
                        />
                    </FormControl>

                    {error && (
                        <FormControl fullWidth={true} variant="outlined" margin="none">
                            <FormHelperText className={classes.formHelperText} error={true}>{error}</FormHelperText>
                        </FormControl>
                    )}

                </DialogContent>
                <DialogActions style={{ padding: "8px 24px 16px 24px" }}>
                    <Button size="small" disabled={isLoading} onClick={handleClose} variant="contained" color="default">Cancel</Button>
                    <Button size="small" disabled={isLoading} onClick={handleSubmit} variant="contained" className={classes.updateButton}>
                        {isLoading ? <CircularProgress color="inherit" size={20} /> : "Update"}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={Boolean(message)}
                autoHideDuration={3000}
                onClose={() => setMessage("")}
                message={message}

            />
        </>
    );

}

export default ShopDetails;
